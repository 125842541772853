/**
 * function tag( name, attrs )
 * Helper function to create document elements with attributes
 */
function tag( name, attrs ) {
  var el = document.createElement( name.toString() );

  !!attrs && Object.keys( attrs ).forEach( function( key ) {
    el.setAttribute( key, attrs[key] );
  } );

  return el;
}

/**
 * dropdownToggle()
 * This function creates the toggle buttons for the dropdown menus
 */
function dropdownToggle() {
  const dropdownToggle = tag( "button", {
    "class": "dropdown-toggle js-dropdown-toggle",
    "aria-expanded": false,
  } );
  const dropdownToggleText = tag( "span", { "class": "screen-reader-text" } );

  dropdownToggle.textContent = "+";
  dropdownToggleText.textContent = wlScreenReaderText.expandMenu;

  dropdownToggle.appendChild( dropdownToggleText );

  return dropdownToggle;
}

/**
 * handleDropdownToggle( e )
 * bound to the eventlistener on the dropdownToggle
 */
function handleDropdownToggle( e ) {
  const subMenu = this.querySelector( ".sub-menu" );

  // only work with buttons
  if ( !e.target.matches( "button" ) ) {
    return;
  }

  const toggleButton = e.target;

  // toggle the symbol used for the button
  toggleButton.firstChild.textContent = toggleButton.firstChild.textContent === "+" ? "–" : "+";

  // toggle the screenReaderText for the button
  toggleButton.lastChild.textContent = toggleButton.lastChild.textContent === wlScreenReaderText.expandMenu ? wlScreenReaderText.collapseMenu : wlScreenReaderText.expandMenu;

  // toggle the show/hide class on the sub menu
  subMenu.classList.toggle( "toggled-off" );

  // toggle the aria attribute on the button
  toggleButton.setAttribute( "aria-expanded", toggleButton.getAttribute( "aria-expanded" ) === "false" ? "true" : "false" );
}

/**
 * openCurrentAncestor( e )
 * Opens the dropdowns of all current ancestor menu items
 */
function openCurrentAncestor( e ) {
  const toggle = e.querySelector( ".js-dropdown-toggle" );
  const subMenu = e.querySelector( ".sub-menu" );

  // handle the toggle
  toggle.firstChild.textContent = "–";
  toggle.lastChild.textContent = wlScreenReaderText.collapseMenu;
  toggle.setAttribute( "aria-expanded", "true" );

  // handle the sub menu
  subMenu.classList.remove( "toggled-off" );
}

/**
 * initMainNavigation( container )
 * Initialize our navigation
 */
function initMainNavigation( container ) {
  const navigation = document.querySelector( container );

  // bail early when there is no navigation at all
  if ( !navigation ) {
    return;
  }

  const subMenus = navigation.querySelectorAll( ".sub-menu" );
  const parentItems = navigation.querySelectorAll( ".menu-item-has-children" );
  const currentAncestors = navigation.querySelectorAll( ".current-menu-ancestor" );

  // enable js styles for the navigation
  navigation.classList.add( "navigation--js" );

  // hide all subMenus
  [].slice.call( subMenus ).forEach( subMenu => subMenu.classList.add( "toggled-off" ) );

  [].slice.call( parentItems ).forEach( function( parentItem ) {
    // add the dropdownToggles
    parentItem.insertBefore( dropdownToggle(), parentItem.querySelector( "a" ) );

    // add the dropdownToggles event listeners
    parentItem.addEventListener( "click", handleDropdownToggle );
  } );

  [].slice.call( currentAncestors ).forEach( currentAncestor => openCurrentAncestor( currentAncestor ) );
}

initMainNavigation( ".js-side-nav" );
