// This script gets the website height and browser window height. Then add
// the defined variable value and calculate if the scroll to top button can be
// inserted. When the scroll to top button is inserted, set it with defined
// values for smooth scrolling to the top.

( function() {
  // set plus scrolling height variable to show the scroll button when page height
  // is greater than screen height + setPlusScrollHeight
  const setPlusScrollHeight = 250;

  // get browser height
  const getBrowserHeight = window.innerHeight;

  // get page height
  const getPageHeight = document.body.offsetHeight;

  // set min height from browser height plus scrolling height
  const setMinHeight = getBrowserHeight + setPlusScrollHeight;

  // check if the scroll to top button can be inserted
  if ( getPageHeight > setMinHeight ) {
    // create the scroll to top button as an anchor element
    const insertScrollToTop = document.createElement( "a" );

    // prepare the scroll to top button and add classes for styling,
    // href attribute to show the cursor and set the screen reader text
    insertScrollToTop.classList.add( "scroll-to-top" );
    insertScrollToTop.classList.add( "icon-arrow-down-black" );
    insertScrollToTop.setAttribute( "href", "#site-header" );
    insertScrollToTop.innerText = wlScreenReaderText.scrollToTop;

    // insert the scroll to top button
    document.body.appendChild( insertScrollToTop );

    // add event listener for calling the scrollToTop function
    insertScrollToTop.addEventListener( "click", scrollToTop );
  }

  // scroll to top smooth function
  function scrollToTop( e ) {
    e.preventDefault();

    window.scroll( {
      top: 0,
      left: 0,
      behavior: "smooth",
    } );
  }
}() );
