/**
 * Initialize the baguetteBox gallery. This uses the baguetteBox dependency, make sure the library is available.
 * Installation via bower: bower install baguettebox.js --save
 *
 * Also make sure that your gallery is within a section with class .WL-gallery
 */

if ( document.querySelector( ".WL-gallery" ) ) {
  baguetteBox.run( ".WL-gallery" );
}
